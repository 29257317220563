import { createApi } from '@reduxjs/toolkit/query/react';
import {
	IProcedureCreateRequest,
	IProcedureList,
	IProcedureRequest,
	IValidateProcedureRequest,
	IProcedureCancelRequest,
	ISingleProcedureRequest,
} from '../../../interfaces/procedure';
import { IApiResponse, IPaginate } from '../../../interfaces/common';
import { baseQuery } from '../common';
import { IDiagnosisSearch } from '../../../interfaces/diagnosis';

export const procedureApi = createApi({
	reducerPath: 'procedureList',
	baseQuery: baseQuery,
	tagTypes: ['ProcedureList', 'ProcedureList'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		uploadProcedureItemsFile: builder.mutation<IApiResponse<IProcedureList[]>, FormData>({
			query: (body) => ({
				url: `/procedure/upload`,
				method: 'POST',
				body,
			}),
		}),
		getProcedureList: builder.query<IApiResponse<IPaginate<IProcedureList[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/procedure?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		searchICD: builder.query<
			IApiResponse<IPaginate<IDiagnosisSearch[]>>,
			{
				keyword: string;
				type: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ keyword = '', type, pagination: { page = 1, limit = 10 } }) =>
				`/icd-search/?keyword=${keyword}&type=${type}&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		getProcedureForAnId: builder.query<IApiResponse<IProcedureList>, string>({
			query: (id) => `/procedure/${id}`,
			//   keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'ProcedureList', id: arg }],
		}),
		addProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query: (body) => ({
				url: `/procedure`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
		setDefaultTest: builder.mutation<any, Partial<IProcedureList>>({
			query(body) {
				return {
					url: `/procedure/setup`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
		searchTest: builder.query<
			IApiResponse<IPaginate<IProcedureList[]>>,
			{
				name?: string;
				category?: string;
				categoryId?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ name, category, categoryId, pagination: { page = 1, limit = 10 } }) =>
				`/procedure/filter?page=${page}&limit=${limit}${name && `&name=${name}`}`,
			providesTags: [{ type: 'ProcedureList' }],
		}),
		updateProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query(data) {
				return {
					url: `/procedure/${data.id}`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'ProcedureList', id: arg.id }, 'ProcedureList'],
		}),
		generateProcedureList: builder.mutation({
			query() {
				return {
					url: '/procedure/generate',
					method: 'POST',
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		deleteProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query: ({ id }) => ({
				url: `/procedure/${id}`,
				method: 'DELETE',
				body: id,
			}),
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
		getProcedureICD: builder.query<
			IApiResponse<IPaginate<IDiagnosisSearch[]>>,
			{
				keyword?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ keyword = '', pagination: { page = 1, limit = 10 } }) => `/icd-pcs/?keyword=${keyword}&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		createProcedureRequest: builder.mutation<any, IProcedureCreateRequest>({
			query(data) {
				return {
					url: `/procedure-request`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		editProcedureRequest: builder.mutation<any, { data: IProcedureCreateRequest; id: string }>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		cancelProcedureRequest: builder.mutation<any, { data: IProcedureCancelRequest; id: string }>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/cancel`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		validateSurgeonAvailability: builder.mutation<any, IValidateProcedureRequest>({
			query(data) {
				return {
					url: `/procedure-request/checkOverlap`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		getProcedureRequest: builder.query<
			IApiResponse<IPaginate<IProcedureRequest[]>>,
			{
				pagination: { page: number; limit: number };
				status: 'scheduled' | 'in_progress' | 'cancelled' | 'completed';
				consultantId?: string;
				startDate?: string;
				endDate?: string;
				searchTerm?: string;
				searchBy?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, status, consultantId, startDate, endDate, searchTerm, searchBy }) =>
				`/procedure-request?page=${page}&limit=${limit}${status ? `&status=${status}` : ''}${searchTerm ? `&searchTerm=${searchTerm}` : ''}${
					searchBy ? `&searchBy=${searchBy}` : ''
				}${consultantId ? `&consultantId=${consultantId}` : ''}${startDate ? `&startDate=${startDate}` : ''}${
					endDate ? `&endDate=${endDate}T11:59:59.000Z` : ''
				}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		getProcedureRequestItem: builder.query<IApiResponse<ISingleProcedureRequest>, { id: string }>({
			query: ({ id }) => `/procedure-request/${id}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		updatePreSurgeryAssessment: builder.mutation<any, { data: { asaClass: string }; id: string }>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/pre-surgery-assessment`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		uploadConsentForm: builder.mutation<
			any,
			{
				data: {
					fileName: string;
					filePath: string;
					docType: string;
					uploadedById: string;
					uploadedByName: string;
				};
				id: string;
			}
		>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/consent-form`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		deleteConsentForm: builder.mutation<
			any,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/procedure-request/${id}/consent-form`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		deleteOtherForm: builder.mutation<
			any,
			{
				id: string;
				docId: string;
			}
		>({
			query({ id, docId }) {
				return {
					url: `/procedure-request/${id}/other-docs/${docId}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		uploadOtherForm: builder.mutation<
			any,
			{
				data: {
					fileName: string;
					filePath: string;
					docType: string;
					uploadedById: string;
					uploadedByName: string;
				}[];
				id: string;
			}
		>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/other-docs`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		addSurgeon: builder.mutation<
			any,
			{
				data: {
					consultantDtos: {
						id: string;
						name: string;
					}[];
					procedureName: string;
					patientFullname: string;
				};
				id: string;
			}
		>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/add-surgeons`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		removeSurgeon: builder.mutation<
			any,
			{
				consultantId: string;
				id: string;
				data: {
					consultantName: string;
					procedureName: string;
					patientFullname: string;
				};
			}
		>({
			query({ consultantId, id, data }) {
				return {
					url: `/procedure-request/${id}/remove-surgeon/${consultantId}`,
					method: 'DELETE',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		startSurgery: builder.mutation<
			any,
			{
				id: string;
				data: {
					availableConsultantIds: string[];
				};
			}
		>({
			query({ id, data }) {
				return {
					url: `/procedure-request/${id}/start`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		updateProcedureDocumentation: builder.mutation<
			any,
			{
				data: {
					updatedById: string;
					updatedByName: string;
					operationsPerformed: string[];
					indicationForSurgery: string;
					surgicalApproach: string;
					anaesthesiaType: string;
					anaestheisologistId: string;
					findings: string;
					specimenRemoved: string;
					depositionOfSpecimen: string;
					initialInstrumentCount: number;
					finalInstrumentCount: number;
					tourniquetStartTime: Date;
					tourniquetEndTime: Date;
					patientPositioning: string;
					temperatureManagement: string;
					typeOfFluids: string;
					volume: number;
					volumeUOM: 'ml';
					EBL: number;
					EBL_UOM: 'ml';
					bloodProductGiven: string;
					urine: number;
					urineUOM: 'ml';
					conditionAtEndOfSurgery: string;
					surgicalSiteStatus: string;
					postOpInstructions: string;
					painManagementPlan: string;
					summary: string;
				};
				id: string;
			}
		>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/documentation`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		submitProcedureDocumentation: builder.mutation<
			any,
			{
				data: {
					submittedById: string;
					submittedByName: string;
				};
				id: string;
			}
		>({
			query({ data, id }) {
				return {
					url: `/procedure-request/${id}/documentation/submit`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
	}),
});

export const {
	useUploadProcedureItemsFileMutation,
	useGetProcedureListQuery,
	useGetProcedureForAnIdQuery,
	useSearchICDQuery,
	useGetProcedureICDQuery,
	useAddProcedureListMutation,
	useUpdateProcedureListMutation,
	useSetDefaultTestMutation,
	useGenerateProcedureListMutation,
	useDeleteProcedureListMutation,
	usePrefetch,
	useCreateProcedureRequestMutation,
	useEditProcedureRequestMutation,
	useCancelProcedureRequestMutation,
	useValidateSurgeonAvailabilityMutation,
	useGetProcedureRequestQuery,
	useGetProcedureRequestItemQuery,
	useUpdatePreSurgeryAssessmentMutation,
	useUploadConsentFormMutation,
	useDeleteConsentFormMutation,
	useDeleteOtherFormMutation,
	useUploadOtherFormMutation,
	useAddSurgeonMutation,
	useRemoveSurgeonMutation,
	useStartSurgeryMutation,
	useUpdateProcedureDocumentationMutation,
	useSubmitProcedureDocumentationMutation,
} = procedureApi;
