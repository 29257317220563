import React from 'react';
import Guides from '../data/HospitalLeftNavItems';
import Logo from '../assets/images/civislogoonly.png';
import Logo2 from '../assets/images/civic health white2-04.png';
import styles from './component.module.css';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { loguserOut, timeoutFunc } from '../app/hospitalRedux/userSlice';
import { useLocation } from 'react-router-dom';
import { CheckPermission } from '../pages/HospitalAdmin/Dashboard';

const LeftSide = ({ nav }) => {
	const path: any = useLocation();

	const dispatch = useAppDispatch();
	const history = useHistory();

	const handleLogout = () => {
		dispatch(loguserOut());
		localStorage.clear();
		dispatch(timeoutFunc({ payload: false }));
		history.push('/hospital/login');
	};

	return (
		<div
			className={`${nav ? styles.onCont : styles.container} bg-primary gap-2 py-4 d-flex flex-column  h-100 `}
			style={{ minWidth: '5em' }}
		>
			<div className="d-flex align-items-center justify-content-center" style={{ maxWidth: '5em', maxHeight: '4em' }}>
				<img src={Logo} height={60} className={`py-2 ${styles.logo}`} alt="" />
				<img src={Logo2} height={90} className={`py-2 ${styles.logo2} ml-5`} alt="" />
			</div>

			<div className="d-flex h-75 text-light mt-4">
				<div className="w-100" style={{ minWidth: '4em' }}>
					<ul className="d-flex flex-column nav m-0 p-0" style={{ fontSize: '1.1em', listStyle: 'none', zIndex: 1 }}>
						{Guides.map((item) =>
							(item?.permission ? CheckPermission(item.permission) : true) ? (
								item?.dropDown?.length ? (
									<li
										className={`${styles.listObject} 
                ${
					item?.dropDown?.map((drop) => drop?.path).includes(path.pathname.split('/hospital/')[1])
						? styles.active
						: styles.inactive
				} w-100`}
										style={{
											alignItems: 'center',
											cursor: 'pointer',
											zIndex: '1',
										}}
									>
										<div
											className="d-flex align-items-center gap-3 py-2"
											style={{ minWidth: '5em', fontSize: '1em', height: '3em' }}
										>
											<i className={`${item?.icon} ml-4`}></i>
											{<span className={`${styles.leftnavBar}`}>{item?.name}</span>}
										</div>
										<ul>
											{item?.dropDown?.map((dropDownItem) =>
												(dropDownItem?.permission ? CheckPermission(dropDownItem.permission) : true) ? (
													<li
														className={`${styles.listObject} 
                ${path.pathname.split('/hospital/')[1] === dropDownItem?.path ? styles.active : styles.inactive} w-100 d-flex`}
														onClick={(e: any) => {
															if (dropDownItem?.path) {
																history.push(`/hospital/${dropDownItem?.path}`);
																window.scrollTo(0, 0);
															}
														}}
														style={{ alignItems: 'center', cursor: 'pointer', zIndex: '1' }}
													>
														<div
															className="d-flex align-items-center gap-3"
															style={{ minWidth: '5em', fontSize: '1em', height: '2.5em' }}
														>
															<i className={`${dropDownItem?.icon} ml-2`}></i>
															{<span className={`${styles.leftnavBar}`}>{dropDownItem?.name}</span>}
														</div>
													</li>
												) : null
											)}
										</ul>
									</li>
								) : (
									<li
										className={`${styles.listObject} 
                ${path.pathname.split('/')[2] === item?.path ? styles.active : styles.inactive} w-100 d-flex`}
										onClick={(e: any) => {
											if (item?.path) {
												history.push(`/hospital/${item?.path}`);
												window.scrollTo(0, 0);
											}
										}}
										style={{ alignItems: 'center', cursor: 'pointer', zIndex: '1' }}
									>
										<div
											className="d-flex align-items-center gap-3 py-2"
											style={{ minWidth: '5em', fontSize: '1em', height: '3em' }}
										>
											<i className={`${item?.icon} ml-4`}></i>
											{<span className={`${styles.leftnavBar}`}>{item?.name}</span>}
										</div>
									</li>
								)
							) : null
						)}
					</ul>

					<div
						style={{ cursor: 'pointer', minHeight: '3em' }}
						onClick={() => handleLogout()}
						className={`py-2 ${styles.listObject} d-flex nav-item gap-2 logout  py-2 mt-5`}
					>
						<i className="bi bi-box-arrow-right ml-4"></i>

						<span className={`${styles.leftnavBar}`}>Logout</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeftSide;
