type Default = {
	name: string;
	path?: string;
	icon?: string;
	permission?: string;
	isHidden?: boolean;
};

const Guides: Array<
	Default & {
		dropDown?: Default[];
	}
> = [
	{
		name: 'Dashboard',
		path: 'dashboard',
		icon: 'bi bi-grid',
		permission: '',
	},
	{
		name: 'Appointments',
		path: 'appointments',
		icon: 'bi bi-calendar3',
		permission: 'View Appointment',
	},
	{
		name: 'Queue Management',
		path: 'queue-management',
		icon: 'fa fa-user-plus',
		permission: 'View QMS Per Department',
	},
	{
		name: 'Patients',
		path: 'patients',
		icon: 'fas fa-users',
		permission: 'View Patient List',
	},
	{
		name: 'Medication',
		path: 'medications',
		icon: 'fas fa-capsules',
		permission: 'View Medication Requests',
	},
	{
		name: 'Admissions',
		path: 'admissions',
		icon: 'fas fa-hotel',
		permission: 'View Admission',
	},
	{
		name: 'Procedure',
		path: 'procedure',
		icon: 'fas fa-procedures',
		permission: 'View Procedure Requests',
	},
	{
		name: 'Labs',
		path: 'labs',
		icon: 'fas fa-microscope',
		permission: 'View Lab Test Requests',
	},
	{
		name: 'Blood Management',
		path: 'blood-management',
		icon: 'fa fa-tint ',
		permission: 'View Blood Bank',
	},
	{
		name: 'Radiology',
		path: 'radiology',
		icon: 'fa fa-user-md',
		permission: 'View Radiology Test Requests',
	},
	{
		name: 'Inventory',
		path: 'inventory',
		icon: 'fas fa-boxes',
		permission: 'View Products',
	},
	{
		name: 'Vendor',
		path: 'vendor',
		icon: 'fa fa-cart-arrow-down',
		permission: 'View Vendors',
	},
	{
		name: 'HMO Management',
		icon: 'fas fa-hospital',
		path: 'hmo-management',
		permission: 'View Health Plan Partner Card',
	},
	// {
	// 	name: 'HMO Management',
	// 	icon: 'fas fa-hospital',
	// 	permission: 'View Health Plan Partner Card',
	// 	dropDown: [
	// 		{
	// 			name: 'HMO/HPP',
	// 			permission: '',
	// 			icon: '',
	// 			path: 'hmo-management',
	// 		},
	// 		// {
	// 		// 	name: "Approval",
	// 		// 	permission: "",
	// 		// 	icon: "",
	// 		// 	path: "hmo-management/approval",
	// 		// },
	// 		// {
	// 		// 	name: "Claims",
	// 		// 	permission: "",
	// 		// 	icon: "",
	// 		// 	path: "hmo-management/claims",
	// 		// },
	// 	],
	// },
	{
		name: 'Billing & Collection',
		path: 'billing',
		icon: 'fas fa-money-bill',
		permission: 'View Billing Module',
	},
	{
		name: 'Report',
		path: 'report',
		icon: 'bi bi-file-text-fill',
		permission: 'View Report Module',
	},
	// {
	// 	name: 'Administrative',
	// 	path: 'admin',
	// 	icon: 'fas fa-user-shield',
	// 	permission: '',
	// },
	{
		name: 'Settings',
		path: 'settings',
		icon: 'fas fa-cog',
		permission: 'View Settings',
	},
];

export default Guides.filter((guide) => !guide?.isHidden);
