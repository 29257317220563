import { StyledContainer } from './styles';

const PaymentStatus = ({
	status,
}: {
	status: string /*'uninvoiced' | 'voided' | 'pending payment' | 'partially paid' | 'written off' | 'paid'*/;
}) => {
	status = status || 'uninvoiced';

	return <StyledContainer className={status?.replaceAll('-', '_')?.replaceAll(' ', '_')}>{status}</StyledContainer>;
};
export default PaymentStatus;
