import styled from 'styled-components';

export const StyledContainer = styled.div`
	padding: 1px 16px;
	border-radius: 12px;
	font-weight: 600;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #f6fbfe;
	width: max-content;
	text-transform: capitalize;
	background: #0f4e71;
	display: inline-block;
	height: fit-content;

	&.voided {
		background: #dc3545;
	}
	&.pending_payment {
		background: #eb0509;
	}
	&.partially_paid {
		background: #f89327;
	}
	&.written_off {
		background: #378fc5;
	}
	&.paid {
		background: #08591f;
	}
`;
